export const invoiceCs = {
  invoices: {
    linkedPaymentsModal: {
      title: "Zpárované platby pro fakturu {name}",
      noRecords: "K této faktuře nebyly nalezený žádné zpárované platby",
      linkPayment: "Zpárovat platbu",
      deletePrompt:
        "Opravdu chcete zrušit vazbu na platbu: <strong>{payment}</strong>?",
      linkPrompt:
        "Opravdu chcete vytvořit vazbu na platbu: <strong>{payment}</strong>?",
    },
    paymentSelectorModal: {
      title: "Vyberte platbu pro zpárování s fakturou {name}",
      selectPayment: "Potvrdit",
    },
    paymentStates: {
      paid: "plně uhrazena",
      "not-paid": "neuhrazena",
      overpaid: "uhrazená částka je vyšší než hodnota faktury",
      underpaid: "uhrazená částka je nižší než hodnota faktury",
    },
    toolTips: {
      edit: "Upravit fakturu {invoice}",
      delete: "Odstranit fakturu {invoice}",
      cancel: "Stornovat fakturu {invoice}",
      restore: "Obnovit fakturu {invoice}",
      clone: "Duplikovat fakturu {invoice}",
      client: "Zobrazit klienta pro fakturu {invoice}",
      download: "Stáhnout pdf k faktuře {invoice}",
      paymentInstructions: "Stáhnout informace k platbě pro {invoice}",
      sendEmail: "Odeslat email s fakturou {invoice} klientovi",
      setPaid: "Označit fakturu {invoice} jako `zaplacená`",
      setUnPaid: "Označit fakturu {invoice} jako `nezaplacená`",
      linkedPayments:
        "Zpárované platby pro fakturu {invoice} <br /> Stav platby: <strong>{payment_state}</strong>",
    },
    editTitle: "Upravit fakturu",
    newTitle: "Nová faktura",
    addInvoice: "Přidat fakturu",
    accountantExport: "Účetní export",
    errors: {
      notFound: "Faktura id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit fakturu id {id}",
      invoiceNumberExists: "Faktura s cislem `{name}` již existuje",
      commonGET_INVOICE_BY_ID: "Chyba při získávání dat o faktuře",
      commonGET_INVOICE_PDF_BY_ID: "Chyba při získávání PDF faktury",
      commonDELETE_INVOICE_BY_ID: "Chyba při odstraňování faktury",
      commonCANCEL_INVOICE_BY_ID: "Chyba při stornování faktury",
      commonDELETE_INVOICES: "Nepodařilo se odstranit fakturu",
      SEND_INVOICE_PDF_BY_ID: "Nepodařilo se odeslat fakturu",
      GET_INVOICE_PDF_BY_ID: "Nepodařilo se vygenerovat PDF faktury",
      GET_INVOICE_PAYMENT_INSTRUCTIONS_BY_ID:
        "Nepodařilo se vygenerovat intrukce k platbě",
      GHANGE_INVOICE_PAID_STATUS_BY_ID:
        "Nepodařilo se upravit stav platby u faktury",
      cantGetInvoices: "Chyba při získávání dat o fakturách",
      cantGetNextInvoiceNumber: "Chyba při generování nového čísla faktury",
      cantGetOrganizationsByUserGroupId:
        "Chyba při získávání organizací klienta",
      cantGetAccountantExport: "Nepodařilo se vygenerovat export pro účetního",
      cantGetExport: "Nepodařilo se vygenerovat export",
    },
    form: {
      number: "Číslo",
      company: "Firma",
      organization: "Organizace",
      userProgram: "Program",
      name: "Název",
      currency: "Měna",
      payment: "Platba",
      issueDate: "Datum vystavení",
      paymentDate: "Datum splatnosti",
      taxDate: "Datum zdaňitelného plnění",
      logo: "Logo",
      email: "Email",
      country: "Země",
      client: "Klient",
      street: "Ulice",
      city: "Obec",
      zip: "PSČ",
      in: "IČ",
      tin: "DIČ",
      invoiceChanged: "Faktura byla upravena",
      invoiceChangedFail: "Nepodařilo se upravit fakturu",
      invoicePaymentsChangedFail: "Nepodařilo se upravit platby pro fakturu",
      invoicePaymentUnlinked: "Vazba na platbu byla zrušena",
      invoicePaymentLinked: "Platba byla zpárována s fakturou",
      invoiceAdded: "Nová faktura byla vytvořena",
      invoiceSent: "Faktura byla odeslána na `{email}`",
      invoicePaid: "Faktura byla označena jako zaplacená",
      invoiceUnPaid: "Faktura byla označena jako nezaplacená",
      invoiceAddFail: "Nepodařilo se vytvořit novou fakturu",
      addItem: "Přidat položku",
      supplier: "Dodavatel",
      customer: "Odběratel",
      items: "Položky",
      notes: "Poznámky",
      note_before: "Před položkami",
      note_after: "Za položkami",
      bankAccount: "Bank. účet",
      company_bank_name: "Název banky",
      company_bank_account: "Č.účtu",
      company_bank_iban: "IBAN",
      company_bank_swift_bic: "SWIFT/BIC",
      productName: "Název",
      quantity: "Množství",
      vat: "DPH",
      vat_rate: "Sazba DPH",
      price: "Cena s DPH",
      noItems: "Musíte zadat alespoň jednu položku faktury",
      findClient: "Hledat (jméno / příjmení)",
    },
    table: {
      header: {
        number: "Číslo",
        issueDate: "Datum vystavení",
        paymentDate: "Datum splatnosti",
        company: "Firma",
        client: "Klient",
        organization: "Organizace",
        issuer: "Vystavil",
        supplier: "Dodavatel",
        totalPrice: "Cena celkem s DPH",
        customer: "Odběratel",
        actions: "Akce",
      },
      showAll: "Všechny faktury",
      showUnpaid: "Pouze nezaplacené",
      hideCanceled: "Skrýt stornované",
      showCanceled: "Zobrazit stornované",
      cancelInvoiceQuestion:
        "Opravdu chcete stornovat fakturu <strong>{invoice}</strong>?",
      restoreInvoiceQuestion:
        "Opravdu chcete obnovit fakturu <strong>{invoice}</strong>?",
      deleteInvoiceQuestion:
        "Opravdu chcete odstranit fakturu <strong>{invoice}</strong>?",
      sendInvoiceQuestion:
        "Opravdu chcete odeslat fakturu <strong>{invoice}</strong> na <strong>{email}</strong>?",
      searchInvoices: "Hledat fakturu",
      invoiceDeleted: "Faktura {invoice} byla odstraněna",
      invoiceCanceled: "Faktura {invoice} byla stornována",
      invoiceRestored: "Faktura {invoice} byla obnovena",
      invoicesDeleted: "Faktury byly odstraněny",
      noRecords: "Nebyly nalezeny žádné faktury",
    },
  },
};
