enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  FORGOT_PASSWORD = "forgotPassword",
  CHANGE_PASSWORD = "changePassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  //Dashboard
  GET_DASHBOARD = "getDashboard",
  LOAD_DASHBOARD_MODULES = "loadDashboardModules",

  // User
  GET_USER_BY_ID = "getUserById",
  GET_USER_BY_ID_SIMPLE = "getUserByIdSimple",
  DELETE_USERS = "deleteUsers",
  DELETE_USER_BY_ID = "deleteUserById",
  INVITE_USER = "inviteUser",
  LOAD_USERS = "loadUsers",
  LOAD_CLIENTS = "loadClients",
  LOAD_OVERSEERS = "loadOverseers",
  LOAD_CLIENTS_LIST = "loadClientsList",
  LOAD_CLIENTS_WITHOUT_TRAINING = "loadClientsWithoutTraining",
  LOAD_CLIENTS_WIDGET_DATA = "loadClientsWidgetData",
  LOAD_GUESTS = "loadGuests",
  LOAD_SPECIALISTS = "loadSpecialists",
  UPDATE_USER = "updateUser",
  UPDATE_USER_NO_RELATIONS = "updateUserNoRelations",
  UPLOAD_AVATAR = "uploadAvatar",
  UPDATE_USER_PASSWORD = "updateUserPassword",
  CHECK_PRIVACY_CHANGE = "checkPrivacyChange",
  GDPR_CONSENT = "gdprConsent",
  UPDATE_USER_CONSENTS = "updateUserConsents",
  GET_USER_DIAGNOSTIC_DATA_BY_ID = "getUserDiagnosticDataById",
  GET_USER_ACTIVE_PROGRAMS = "getUserActivePrograms",

  // UserGroup
  GET_USER_GROUP_BY_ID = "getUserGroupById",
  DELETE_USER_GROUPS = "deleteUserGroups",
  DELETE_USER_GROUP_BY_ID = "deleteUserGroupById",
  LOAD_USER_GROUPS = "loadUserGroups",
  UPDATE_USER_GROUP = "updateUserGroup",
  CREATE_USER_GROUP = "createUserGroup",

  // Specialization
  GET_SPECIALIZATION_BY_ID = "getSpecializationById",
  DELETE_SPECIALIZATIONS = "deleteSpecializations",
  DELETE_SPECIALIZATION_BY_ID = "deleteSpecializationById",
  LOAD_SPECIALIZATIONS = "loadSpecializations",
  UPDATE_SPECIALIZATION = "updateSpecialization",
  CREATE_SPECIALIZATION = "createSpecialization",

  // Tool
  UPLOAD_DIAGNOSTIC_IMPORT_FILE = "uploadDiagnosticImportFile",
  UPLOAD_LEADS_IMPORT_FILE = "uploadLeadsImportFile",
  GET_TOOL_BY_ID = "getToolById",
  DELETE_TOOLS = "deleteTools",
  DELETE_TOOL_BY_ID = "deleteToolById",
  LOAD_TOOLS = "loadTools",
  UPDATE_TOOL = "updateTool",
  CREATE_TOOL = "createTool",
  STORE_GAME_RESULTS = "storeGameResults",
  GET_GAME_RESULTS_FOR_USER = "getGameResultsForUser",

  // Payment
  GET_PAYMENT_BY_ID = "getPaymentById",
  GET_FREE_PAYMENTS_BY_INVOICE_ID = "getFreePaymentsByInvoiceId",
  DELETE_PAYMENTS = "deletePayments",
  DELETE_PAYMENT_BY_ID = "deletePaymentById",
  LOAD_PAYMENTS = "loadPayments",
  UPDATE_PAYMENT = "updatePayment",
  CREATE_PAYMENT = "createPayment",

  // Sport
  GET_SPORT_BY_ID = "getSportById",
  DELETE_SPORTS = "deleteSports",
  DELETE_SPORT_BY_ID = "deleteSportById",
  LOAD_SPORTS = "loadSports",
  UPDATE_SPORT = "updateSport",
  CREATE_SPORT = "createSport",

  // Invoice
  GET_INVOICE_BY_ID = "getInvoiceById",
  DOWNLOAD_INVOICE_PDF_BY_ID = "downloadInvoicePdfById",
  DOWNLOAD_PAYMENT_INSTRUCTIONS_BY_ID = "downloadPaymentInstructionsById",
  SEND_INVOICE_PDF_BY_ID = "sendInvoicePdfById",
  ACCOUNTANT_INVOICE_EXPORT = "accountatInvoiceExport",
  INVOICE_EXPORT = "invoiceExport",
  CHANGE_PAID_STATUS_BY_ID = "changePaidStatusById",
  DELETE_INVOICES = "deleteInvoices",
  DELETE_INVOICE_BY_ID = "deleteInvoiceById",
  CANCEL_INVOICE_BY_ID = "cancelInvoiceById",
  LOAD_INVOICES = "loadInvoices",
  UPDATE_INVOICE = "updateInvoice",
  UPDATE_INVOICE_PAYMENTS = "updateInvoicePayments",
  CREATE_INVOICE = "createInvoice",
  GET_NEXT_INVOICE_NUMBER = "getNextInvoiceNumber",

  // RegistrationToken
  GET_REGISTRATION_TOKEN_BY_ID = "getRegistrationTokenById",
  DELETE_REGISTRATION_TOKENS = "deleteRegistrationTokens",
  DELETE_REGISTRATION_TOKEN_BY_ID = "deleteRegistrationTokenById",
  LOAD_REGISTRATION_TOKENS = "loadRegistrationTokens",
  UPDATE_REGISTRATION_TOKEN = "updateRegistrationToken",
  CREATE_REGISTRATION_TOKEN = "createRegistrationToken",

  // CompanyBankAccount
  GET_COMPANY_BANK_ACCOUNT_BY_ID = "getCompanyBankAccountById",
  DELETE_COMPANY_BANK_ACCOUNTS = "deleteCompanyBankAccounts",
  DELETE_COMPANY_BANK_ACCOUNT_BY_ID = "deleteCompanyBankAccountById",
  LOAD_COMPANY_BANK_ACCOUNTS = "loadCompanyBankAccounts",
  UPDATE_COMPANY_BANK_ACCOUNT = "updateCompanyBankAccount",
  CREATE_COMPANY_BANK_ACCOUNT = "createCompanyBankAccount",
  GET_COMPANY_BANK_ACCOUNT_SYNC_STATE = "getCompanyBankAccountSyncState",
  RUN_COMPANY_BANK_ACCOUNT_SYNC = "runCompanyBankAccountSync",

  // Notification
  GET_NOTIFICATION_BY_ID = "getNotificationById",
  DELETE_NOTIFICATIONS = "deleteNotifications",
  DELETE_NOTIFICATION_BY_ID = "deleteNotificationById",
  LOAD_NOTIFICATIONS = "loadNotifications",
  UPDATE_NOTIFICATION = "updateNotification",
  CREATE_NOTIFICATION = "createNotification",

  // Language
  GET_LANGUAGE_BY_ID = "getLanguageById",
  DELETE_LANGUAGES = "deleteLanguages",
  DELETE_LANGUAGE_BY_ID = "deleteLanguageById",
  LOAD_LANGUAGES = "loadLanguages",
  UPDATE_LANGUAGE = "updateLanguage",
  CREATE_LANGUAGE = "createLanguage",

  // Accessory
  GET_ACCESSORY_BY_ID = "getAccessoryById",
  DELETE_ACCESSORIES = "deleteAccessories",
  DELETE_ACCESSORY_BY_ID = "deleteAccessoryById",
  LOAD_ACCESSORIES = "loadAccessories",
  UPDATE_ACCESSORY = "updateAccessory",
  CREATE_ACCESSORY = "createAccessory",

  // BodyPart
  GET_BODY_PART_BY_ID = "getBodyPartById",
  DELETE_BODY_PARTS = "deleteBodyParts",
  DELETE_BODY_PART_BY_ID = "deleteBodyPartById",
  LOAD_BODY_PARTS = "loadBodyParts",
  UPDATE_BODY_PART = "updateBodyPart",
  CREATE_BODY_PART = "createBodyPart",

  // ExerciseExecution
  GET_EXERCISE_EXECUTION_BY_ID = "getExerciseExecutionById",
  DELETE_EXERCISE_EXECUTIONS = "deleteExerciseExecutions",
  DELETE_EXERCISE_EXECUTION_BY_ID = "deleteExerciseExecutionById",
  LOAD_EXERCISE_EXECUTIONS = "loadExerciseExecutions",
  UPDATE_EXERCISE_EXECUTION = "updateExerciseExecution",
  CREATE_EXERCISE_EXECUTION = "createExerciseExecution",

  // Unit
  GET_UNIT_BY_ID = "getUnitById",
  DELETE_UNITS = "deleteUnits",
  DELETE_UNIT_BY_ID = "deleteUnitById",
  LOAD_UNITS = "loadUnits",
  UPDATE_UNIT = "updateUnit",
  CREATE_UNIT = "createUnit",

  // Exercise
  GET_EXERCISE_BY_ID = "getExerciseById",
  DELETE_EXERCISES = "deleteExercises",
  DELETE_EXERCISE_BY_ID = "deleteExerciseById",
  LOAD_EXERCISES = "loadExercises",
  UPDATE_EXERCISE = "updateExercise",
  CREATE_EXERCISE = "createExercise",

  // Exercise Category
  GET_EXERCISE_CATEGORY_BY_ID = "getExerciseCategoryById",
  DELETE_EXERCISE_CATEGORIES = "deleteExerciseCategories",
  DELETE_EXERCISE_CATEGORY_BY_ID = "deleteExerciseCategoryById",
  LOAD_EXERCISE_CATEGORIES = "loadExerciseCategories",
  UPDATE_EXERCISE_CATEGORY = "updateExerciseCategory",
  CREATE_EXERCISE_CATEGORY = "createExerciseCategory",

  // UserContactEvent
  GET_USER_CONTACT_EVENT_BY_ID = "getUserContactEventById",
  DELETE_USER_CONTACT_EVENT_BY_ID = "deleteUserContactEventById",
  LOAD_USER_CONTACT_EVENTS = "loadUserContactEvents",
  SAVE_USER_CONTACT_EVENT = "saveUserContactEvent",

  // Diagnostic
  GET_DIAGNOSTIC_BY_ID = "getDiagnosticById",
  DELETE_DIAGNOSTICS = "deleteDiagnostics",
  DELETE_DIAGNOSTIC_BY_ID = "deleteDiagnosticById",
  LOAD_DIAGNOSTICS = "loadDiagnostics",
  UPDATE_DIAGNOSTIC = "updateDiagnostic",
  CREATE_DIAGNOSTIC = "createDiagnostic",

  // Company
  GET_COMPANY_BY_ID = "getCompanyById",
  DELETE_COMPANIES = "deleteCompanys",
  DELETE_COMPANY_BY_ID = "deleteCompanyById",
  LOAD_COMPANIES = "loadCompanies",
  UPDATE_COMPANY = "updateCompany",
  CREATE_COMPANY = "createCompany",
  UPLOAD_COMPANY_LOGO = "uploadCompanyLogo",
  GET_ORGANIZATIONS_BY_USER_GROUP_ID = "getOrganizationsByUserGroupId",

  // File
  GET_FILE_BY_ID = "getFileById",
  DELETE_FILES = "deleteFiles",
  DELETE_FILE_BY_ID = "deleteFileById",
  LOAD_FILES = "loadFiles",
  UPDATE_FILE = "updateFile",
  CREATE_FILE = "createFile",
  UPLOAD_FILE = "uploadFile",

  // Training
  GET_TRAINING_BY_ID = "getTrainingById",
  DELETE_TRAININGS = "deleteTrainings",
  DELETE_TRAINING_BY_ID = "deleteTrainingById",
  LOAD_TRAININGS = "loadTrainings",
  UPDATE_TRAINING = "updateTraining",
  CREATE_TRAINING = "createTraining",

  // Training template
  GET_TRAINING_TEMPLATE_BY_ID = "getTrainingTemplateById",
  RUN_TRAINING_TEMPLATE_BY_ID = "runTrainingTemplateById",
  CHECK_TRAINING_TEMPLATE_BY_ID = "checkTrainingTemplateById",
  PAUSE_TRAINING_TEMPLATE_BY_ID = "pauseTrainingTemplateById",
  DELETE_TRAINING_TEMPLATES = "deleteTrainingTemplates",
  DELETE_TRAINING_TEMPLATE_BY_ID = "deleteTrainingTemplateById",
  UNLINK_TRAINING_TEMPLATE_BY_ID = "unlinkTrainingTemplateById",
  LOAD_TRAINING_TEMPLATES = "loadTrainingTemplates",
  LOAD_CLIENT_TRAINING_TEMPLATES = "loadClientTrainingTemplates",
  UPDATE_TRAINING_TEMPLATE = "updateTrainingTemplate",
  CREATE_TRAINING_TEMPLATE = "createTrainingTemplate",
  CREATE_SECONDARY_TRAINING_TEMPLATE = "createSecondaryTrainingTemplate",

  LOAD_TRAINING_TEMPLATE_PERIODS = "loadTrainingTemplatePeriods",
  GET_TRAINING_TEMPLATE_PERIOD_BY_ID = "getTrainingTemplatePeriodById",
  DELETE_TRAINING_TEMPLATE_PERIOD_BY_ID = "deleteTrainingTemplatePeriodById",
  UPDATE_TRAINING_TEMPLATE_PERIOD = "updateTrainingTemplatePeriod",
  CREATE_TRAINING_TEMPLATE_PERIOD = "createTrainingTemplatePeriod",

  LOAD_TEMPLATE_PERIOD_EXERCISES = "loadTemplatePeriodsExercises",
  GET_TEMPLATE_PERIOD_EXERCISE_BY_ID = "getTemplatePeriodExerciseById",
  DELETE_TEMPLATE_PERIOD_EXERCISE_BY_ID = "deleteTemplatePeriodExerciseById",
  FINISH_TEMPLATE_PERIOD_EXERCISE_BY_ID = "finishTemplatePeriodExerciseById",
  UPDATE_TEMPLATE_PERIOD_EXERCISE = "updateTemplatePeriodExercise",
  UPDATE_AND_REGENERATE_TEMPLATE_PERIOD_EXERCISE = "updateAndRegenerateTemplatePeriodExercise",
  CREATE_TEMPLATE_PERIOD_EXERCISE = "createTemplatePeriodExercise",

  LOAD_DEFAULT_METRONOME = "loadDefaultMetronome",

  // Training Week
  UPLOAD_TRAINING_WEEK_FILE = "uploadTrainingWeekFile",

  // Training Event
  GET_SCHEDULE = "getSchedule",
  GET_SCHEDULED_ITEMS_BY_WEEK = "getScheduledItemsByWeek",
  OVERRIDE_TRAINING_WEEK = "overrideTrainingWeek",
  MARK_NOTE_AS_DISPLAYED = "markNoteAsDisplayed",

  // Country
  GET_COUNTRIES = "getCountries",

  // User game data
  LOAD_USER_GAME_DATA_BY_USER_AND_GAME = "loadUserGameDataByUserAndGame",

  // Program
  GET_PROGRAM_BY_ID = "getPRogramById",
  DELETE_PROGRAMS = "deletePRograms",
  DELETE_PROGRAM_BY_ID = "deletePRogramById",
  LOAD_PROGRAMS = "loadPRograms",
  UPDATE_PROGRAM = "updatePRogram",
  CREATE_PROGRAM = "createPRogram",

  // Chat message
  GET_CONVERSATION = "getConversation",
  GET_MESSAGES_HISTORY = "getMessagesHistory",
  ADD_MESSAGE = "addMessage",
  GET_SMS_BALANCE = "getSmsBalance",
  SEND_BULK_MESSAGE = "sendBulkMessage",
  READ_ALL_MESSAGES = "readAllMessages",
  MARK_AS_UNREAD = "markAsUnread",

  // Diagnostic import batch
  PROCESS_DIAGNOSTIC_IMPORT_BATCH = "processDiagnosticImportBatch",
  // Leads import batch
  PROCESS_LEADS_IMPORT_BATCH = "processLeadsImportBatch",

  // Diagnostic export
  DIAGNOSTICS_EXPORT = "diagnostic_export",

  // Widgets
  LOAD_COACH_BOARD_DATA = "LoadCoachBoardData",
  LOAD_COACH_BOARD_RADAR_DATA = "LoadCoachBoardRadarData",
  LOAD_NORMATIVE_INDEX_LEGEND = "LoadNormativeIndexLegend",
  EXPORT_SB_STATS = "exportSBStats",
  EXPORT_SB_STATS_INDIVIDUAL_POTENTIAL = "exportSBStatsIndividualPotential",
  GENERATE_COACH_RESULTS_PDF = "generateCoachResultsPdf",

  // Woocommerce
  GET_WC_PRODUCT_BY_ID = "getWcProductById",
  LOAD_WC_PRODUCTS = "loadWcProducts",

  //diagnostic
  CALCULATE_POTENTIALS = "calculatePotentials",

  //results
  GENERATE_USER_RESULTS_PDF = "generateUserResultsPdf",

  //docs
  GENERATE_SLAVOJ_FEE_PAID_CERTIFICATE = "generateSlavojFeePaidCertificate",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_REDIRECT_TO = "setRedirectTo",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_FILTER_CLIENTS_WITH_TRAINING = "setFilterClientsWithTraining",

  PERSIST_WIDGET_OPEN_STATE = "persistWidgetOpenState",

  SET_MY_TRAININGS_COLLAPSED = "setMyTrainingsCollapsed",
  SET_PAGE_IS_LOADING = "setPageIsLoading",
  SET_SHOW_USER_SEARCH_IN_MENU = "setShowUserSearchInMenu",

  //Dashboard
  SET_DASHBOARD_LINK = "setDashboardLink",
  SET_LOADED_DASHBOARD = "setLoadedDashboard",
  SET_LOADED_DASHBOARD_MODULES = "setLoadedDashboardModules",
  SET_DASHBOARD_LOADING = "setDashboardLoading",

  // User
  SET_LOADED_USER = "setLoadedUser",
  SET_USERS = "setLoadedUsers",
  UPDATE_USERS = "updateLoadedUsers",
  SET_CLIENTS = "setLoadedClients",
  SET_OVERSEERS = "setLoadedOverseers",
  SET_CLIENTS_LIST = "setLoadedClientsList",

  SET_CLIENTS_WITHOUT_TRAINING = "setLoadedClientsWithoutTraining",
  SET_CLIENTS_WIDGET_DATA = "setClientWidgetsData",
  SET_MAPPED_BY_GROUPS = "setMappedByGroups",
  SET_CLIENT_GROUP_AVALIABLE = "setClientGroupsAvaliable",
  SET_GUESTS = "setLoadedGuests",
  SET_SPECIALISTS = "setLoadedSpecialists",
  SET_USERS_PAGINATION = "setLoadedUsersPagination",
  SET_USERS_SORTING = "setUsersSorting",
  SET_LOADED_USER_AVATAR = "setLoadedUserAvatar",

  SET_CLIENTS_DATA_LOADING = "setClientsDataLoading",
  SET_CLIENTS_LIST_DATA_LOADING = "setClientsListDataLoading",

  // UserGroup
  SET_LOADED_USER_GROUP = "setLoadedUserGroup",
  SET_USER_GROUPS = "setLoadedUserGroups",
  SET_USER_GROUPS_PAGINATION = "setLoadedUserGroupsPagination",
  SET_USER_GROUPS_SORTING = "setUserGroupsSorting",

  // Specialization
  SET_LOADED_SPECIALIZATION = "setLoadedSpecialization",
  SET_SPECIALIZATIONS = "setLoadedSpecializations",
  SET_SPECIALIZATIONS_PAGINATION = "setLoadedSpecializationsPagination",
  SET_SPECIALIZATIONS_SORTING = "setSpecializationsSorting",

  // Tool
  SET_LOADED_TOOL = "setLoadedTool",
  SET_DEFAULT_METRONOME = "setDefaultMetronome",
  SET_TOOLS = "setLoadedTools",
  SET_TOOLS_PAGINATION = "setLoadedToolsPagination",
  SET_TOOLS_SORTING = "setToolsSorting",

  // Payment
  SET_LOADED_PAYMENT = "setLoadedPayment",
  SET_PAYMENTS = "setLoadedPayments",
  SET_PAYMENTS_PAGINATION = "setLoadedPaymentsPagination",
  SET_PAYMENTS_SORTING = "setPaymentsSorting",

  // Sport
  SET_LOADED_SPORT = "setLoadedSport",
  SET_SPORTS = "setLoadedSports",
  SET_SPORTS_PAGINATION = "setLoadedSportsPagination",
  SET_SPORTS_SORTING = "setSportsSorting",

  // Invoice
  SET_LOADED_INVOICE = "setLoadedInvoice",
  SET_INVOICES = "setLoadedInvoices",
  SET_INVOICES_PAGINATION = "setLoadedInvoicesPagination",
  SET_INVOICES_SORTING = "setInvoicesSorting",

  // RegistrationToken
  SET_LOADED_REGISTRATION_TOKEN = "setLoadedRegistrationToken",
  SET_REGISTRATION_TOKENS = "setLoadedRegistrationTokens",
  SET_REGISTRATION_TOKENS_PAGINATION = "setLoadedRegistrationTokensPagination",
  SET_REGISTRATION_TOKENS_SORTING = "setRegistrationTokensSorting",

  // CompanyBankAccount
  SET_LOADED_COMPANY_BANK_ACCOUNT = "setLoadedCompanyBankAccount",
  SET_COMPANY_BANK_ACCOUNTS = "setLoadedCompanyBankAccounts",
  SET_COMPANY_BANK_ACCOUNTS_PAGINATION = "setLoadedCompanyBankAccountsPagination",
  SET_COMPANY_BANK_ACCOUNTS_SORTING = "setCompanyBankAccountsSorting",

  // Notification
  SET_LOADED_NOTIFICATION = "setLoadedNotification",
  SET_NOTIFICATIONS = "setLoadedNotifications",
  SET_NOTIFICATIONS_PAGINATION = "setLoadedNotificationsPagination",
  SET_NOTIFICATIONS_SORTING = "setNotificationsSorting",

  // Language
  SET_LOADED_LANGUAGE = "setLoadedLanguage",
  SET_LANGUAGES = "setLoadedLanguages",
  SET_LANGUAGES_PAGINATION = "setLoadedLanguagesPagination",
  SET_LANGUAGES_SORTING = "setLanguagesSorting",

  // Accessory
  SET_LOADED_ACCESSORY = "setLoadedAccessory",
  SET_ACCESSORIES = "setLoadedAccessories",
  SET_ACCESSORIES_PAGINATION = "setLoadedAccessoriesPagination",
  SET_ACCESSORIES_SORTING = "setAccessoriesSorting",

  // BodyPart
  SET_LOADED_BODY_PART = "setLoadedBodyPart",
  SET_BODY_PARTS = "setLoadedBodyParts",
  SET_BODY_PARTS_PAGINATION = "setLoadedBodyPartsPagination",
  SET_BODY_PARTS_SORTING = "setBodyPartsSorting",

  // ExerciseExecution
  SET_LOADED_EXERCISE_EXECUTION = "setLoadedExerciseExecution",
  SET_EXERCISE_EXECUTIONS = "setLoadedExerciseExecutions",
  SET_EXERCISE_EXECUTIONS_PAGINATION = "setLoadedExerciseExecutionsPagination",
  SET_EXERCISE_EXECUTIONS_SORTING = "setExerciseExecutionsSorting",

  // Unit
  SET_LOADED_UNIT = "setLoadedUnit",
  SET_UNITS = "setLoadedUnits",
  SET_UNITS_PAGINATION = "setLoadedUnitsPagination",
  SET_UNITS_SORTING = "setUnitsSorting",

  // Exercise
  SET_LOADED_EXERCISE = "setLoadedExercise",
  SET_EXERCISES = "setLoadedExercises",
  SET_EXERCISES_PAGINATION = "setLoadedExercisesPagination",
  SET_EXERCISES_SORTING = "setExercisesSorting",

  // Exercise Category
  SET_LOADED_EXERCISE_CATEGORY = "setLoadedExerciseCategory",
  SET_EXERCISE_CATEGORIES = "setLoadedExerciseCategories",
  SET_EXERCISE_CATEGORIES_PAGINATION = "setLoadedExerciseCategoriesPagination",
  SET_EXERCISE_CATEGORIES_SORTING = "setExerciseCategoriesSorting",

  // UserContactEvent
  SET_LOADED_USER_CONTACT_EVENT = "setLoadedUserContactEvent",
  SET_USER_CONTACT_EVENTS = "setLoadedUserContactEvents",
  SET_USER_CONTACT_EVENTS_PAGINATION = "setLoadedUserContactEventsPagination",
  SET_USER_CONTACT_EVENTS_SORTING = "setUserContactEventsSorting",

  // Diagnostic
  SET_LOADED_DIAGNOSTIC = "setLoadedDiagnostic",
  SET_DIAGNOSTICS = "setLoadedDiagnostics",
  SET_DIAGNOSTICS_PAGINATION = "setLoadedDiagnosticsPagination",
  SET_DIAGNOSTICS_SORTING = "setDiagnosticsSorting",

  // Files
  SET_LOADED_FILE = "setLoadedFile",
  SET_FILES = "setLoadedFiles",
  SET_FILES_PAGINATION = "setLoadedFilesPagination",
  SET_FILES_SORTING = "setFilesSorting",

  // Training
  SET_LOADED_TRAINING = "setLoadedTraining",
  SET_TRAININGS = "setLoadedTrainings",
  SET_TRAININGS_PAGINATION = "setLoadedTrainingsPagination",
  SET_TRAININGS_SORTING = "setTrainingsSorting",

  // TrainingTemplate
  SET_LOADED_TRAINING_TEMPLATE = "setLoadedTrainingTemplate",
  SET_TRAINING_TEMPLATES = "setLoadedTrainingTemplates",
  SET_CLIENT_TRAINING_TEMPLATES = "setLoadedClientTrainingTemplates",
  SET_TRAINING_TEMPLATES_PAGINATION = "setLoadedTrainingTemplatesPagination",
  SET_TRAINING_TEMPLATES_SORTING = "setTrainingTemplatesSorting",

  SET_LOADED_TRAINING_TEMPLATE_PERIOD = "setLoadedTrainingTemplatePeriod",
  SET_TRAINING_TEMPLATE_PERIODS = "setLoadedTrainingTemplatePeriods",
  SET_TRAINING_TEMPLATE_PERIODS_PAGINATION = "setLoadedTrainingTemplatePeriodsPagination",
  SET_TRAINING_TEMPLATE_PERIODS_SORTING = "setTrainingTemplatePeriodsSorting",

  SET_LOADED_TEMPLATE_PERIOD_EXERCISE = "setLoadedTemplatePeriodExercise",
  SET_TEMPLATE_PERIOD_EXERCISE_IS_LOADING = "setTemplatePeriodExerciseIsLoading",
  SET_TEMPLATE_PERIOD_EXERCISES = "setLoadedTemplatePeriodExercises",
  SET_TEMPLATE_PERIOD_EXERCISES_PAGINATION = "setLoadedTemplatePeriodExercisesPagination",
  SET_TEMPLATE_PERIOD_EXERCISES_SORTING = "setTemplatePeriodExercisesSorting",

  // Country
  SET_LOADED_COUNTRIES = "setLoadedCountries",

  // Training Event
  SET_LOADED_SCHEDULED_ITEMS = "setLoadedScheduledItems",

  // User game data
  SET_USER_GAME_DATA = "setLoadedUserGameData",
  SET_USER_GAME_DATA_PAGINATION = "setLoadedUserGameDataPagination",
  SET_USER_GAME_DATA_SORTING = "setUserGameDataSorting",

  // Company
  SET_LOADED_COMPANY = "setLoadedCompany",
  SET_COMPANIES = "setLoadedCompanies",
  SET_COMPANIES_PAGINATION = "setLoadedCompaniesPagination",
  SET_COMPANIES_SORTING = "setCompaniesSorting",

  SET_LOADED_COMPANY_LOGO = "setLoadedCompanyLogo",

  // Program
  SET_LOADED_PROGRAM = "setLoadedProgram",
  SET_PROGRAMS = "setLoadedPrograms",
  SET_PROGRAMS_PAGINATION = "setLoadedProgramsPagination",
  SET_PROGRAMS_SORTING = "setProgramsSorting",

  //Modal
  SET_MODAL_TITLE = "setModalTitle",
  SET_MODAL_BODY = "setModalBody",

  // Chat message
  SET_CHAT_MESSAGES_HISTORY = "setLoadedChatMessagesHIstory",
  SET_CHAT_MESSAGES = "setLoadedChatMessages",
  OPEN_CHAT = "openChat",
  CLOSE_CHAT = "closeChat",
  SET_CHAT_MESSAGES_PAGINATION = "setLoadedChatMessagesPagination",
  SET_CHAT_MESSAGES_SORTING = "setChatMessagesSorting",
  SET_MESSAGE_COUNTS = "setMessageCounts",
  SET_SMS_BALANCE = "setSmsBalance",

  //Senses board
  SET_PLAYERS = "setSBPlayers",
  SET_NI_LEGEND = "SetNiLegend",

  // Woocommerce
  SET_LOADED_WC_PRODUCTS = "setLoadedWcProducts",
  SET_LOADED_WC_PRODUCT = "setLoadedWcProduct",

  // Widgets
  SET_COACH_RADAR_DATA = "setCoachRadarData",
  SET_COACH_RADAR_DATA_LOADING = "setCoachRadarDataLoading",
}

export { Actions, Mutations };
