export const exerciseSk = {
  exercises: {
    toolTips: {
      edit: "Upraviť cvik {exercise}",
      delete: "Odstrániť cvik {exercise}",
      clone: "Duplikovat cvik {exercise}",
    },
    editTitle: "Upraviť cvik",
    newTitle: "Nový cvik",
    cloneTitle: "Kopie cviku `{exercise}`",
    addExercise: "Pridať cvik",
    errors: {
      notFound: "Cvičenie s ID {id} nebolo nájdené",
      notYourRole: "Nemáte práva zobraziť cvik s ID {id}",
      nameExists: "Cvičenie s názvom `{name}` už existuje",
      codeExists: "Cvičenie s kódom `{code}` už existuje",
      commonGET_EXERCISE_BY_ID: "Chyba pri získavaní údajov o cvičeniach",
      commonDELETE_EXERCISE_BY_ID: "Chyba pri odstraňovaní cviku",
      commonDELETE_EXERCISES: "Nepodarilo sa odstrániť cvik",
      cantGetExercises: "Chyba pri získavaní údajov o cvičeniach",
    },
    form: {
      name: "Názov",
      client_name: "Názov pre klienta",
      color: "Farba",
      code: "Kód",
      sports: "Sporty",
      description: "Popis vykonávania",
      short_description: "Krátky popis",
      setup_description: "Popis nastavenia",
      repeats: "Počet opakovaní",
      repeats_unit: "Jednotka opakovaní",
      duration: "Dĺžka cvičenia",
      duration_unit: "Jednotka",
      accessories: "Pomôcky",
      bodyParts: "Časti tela",
      exerciseCategory: "Kategória",
      image: "Obrázok",
      icon: "Ikonka",
      video: "Video",
      company: "Firmy",
      exerciseChanged: "Cvik bol upravený",
      exerciseChangedFail: "Nepodarilo sa upraviť cvik",
      exerciseAdded: "Nový cvik bol vytvorený",
      exerciseAddFail: "Nepodarilo sa vytvoriť nový cvik",
    },
    table: {
      header: {
        name: "Názov",
        icon: "Ikonka",
        code: "Kód",
        accessories: "Pomôcky",
        exerciseCategory: "Kategória",
        exerciseTemplatesCount: "Počet šablon",
        company: "Firmy",
        actions: "Akcie",
      },
      deleteExerciseQuestion:
        "Určite chcete odstrániť cvik <strong>{exercise}</strong>?",
      searchExercises: "Hľadať cvik",
      exerciseDeleted: "Cvik {exercise} bol odstránený",
      exercisesDeleted: "Cviky boli odstránené",
      noRecords: "Neboli nájdené žiadne cviky",
    },
  },
};
