import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type {
  TemplatePeriodExercise,
  TrainingTemplate,
} from "@/store/interfaces/TrainingTemplate";

@Module
export default class TrainingTemplateModule extends VuexModule {
  redirectTo = "";
  loadedTrainingTemplate: TrainingTemplate = {} as TrainingTemplate;
  TrainingTemplates: TrainingTemplate[] = [];
  ClientTrainingTemplates: TrainingTemplate[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getPeriods(): Array<string> {
    return ["day", "week", "month", "year"];
  }

  get getCountingTypes(): Array<string> {
    return ["time_increment", "time_decrement", "time_hold", "activity-pause"];
  }

  get getTypes(): Array<string> {
    return ["auto", "manual", "random"];
  }
  get getStatuses(): Array<string> {
    return ["draft", "template" /*"assigned", */ /* "paused"*/];
  }

  get getAllStatuses(): Array<string> {
    return ["draft", "template", "assigned", "paused", "active"];
  }

  get getLoadedTrainingTemplate(): TrainingTemplate {
    return this.loadedTrainingTemplate.id
      ? this.loadedTrainingTemplate
      : ({
          ...this.loadedTrainingTemplate,
          ...{
            periods: [],
            status: "template",
          },
        } as TrainingTemplate);
  }

  /**
   * Retreive current loaded trainingTemplates
   */
  get getTrainingTemplates(): TrainingTemplate[] {
    return this.TrainingTemplates;
  }
  /**
   * Retreive current loaded clientTrainingTemplates
   */
  get getClientTrainingTemplates(): TrainingTemplate[] {
    return this.ClientTrainingTemplates;
  }

  /**
   * Get last pagination setup
   */
  get getTrainingTemplatesPagination(): PaginationResponse {
    return this.pagination;
  }

  get getTrainingTemplatesSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_TRAINING_TEMPLATE](TrainingTemplate: TrainingTemplate) {
    this.loadedTrainingTemplate = TrainingTemplate;
  }

  @Mutation
  [Mutations.SET_TRAINING_TEMPLATES](data) {
    this.TrainingTemplates = data.data;
  }
  @Mutation
  [Mutations.SET_CLIENT_TRAINING_TEMPLATES](data) {
    this.ClientTrainingTemplates = data;
  }

  @Mutation
  [Mutations.SET_TRAINING_TEMPLATES_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_TRAINING_TEMPLATES_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_TRAINING_TEMPLATES](payload) {
    ApiService.setHeader();
    return new Promise<TrainingTemplate[]>((resolve, reject) => {
      ApiService.post("training-templates", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_TRAINING_TEMPLATES,
              data["training-templates"]
            );
            this.context.commit(
              Mutations.SET_TRAINING_TEMPLATES_PAGINATION,
              data["training-templates"].pagination
            );
            resolve(data["training-templates"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.cantGetTrainingTemplates"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.LOAD_CLIENT_TRAINING_TEMPLATES](payload) {
    ApiService.setHeader();
    return new Promise<TrainingTemplate[]>((resolve, reject) => {
      ApiService.post("training-templates/client-data", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_CLIENT_TRAINING_TEMPLATES,
              data["training-templates"]
            );
            resolve(data["training-templates"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.cantGetClientTrainingTemplates"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_TRAINING_TEMPLATE_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<TrainingTemplate>((resolve) => {
        const TrainingTemplate = {} as TrainingTemplate;
        this.context.commit(
          Mutations.SET_LOADED_TRAINING_TEMPLATE,
          TrainingTemplate
        );
        resolve(TrainingTemplate);
      });
    }
    return new Promise<TrainingTemplate>((resolve, reject) => {
      ApiService.get(`training-template/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE,
              data["training-template"]
            );
            resolve(data["training-template"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.commonGET_TRAINING_TEMPLATE_BY_ID"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.RUN_TRAINING_TEMPLATE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<TrainingTemplate>((resolve, reject) => {
      ApiService.get(`training-template/${id}/run`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE,
              data["training-template"]
            );
            resolve(data["training-template"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.commonGET_TRAINING_TEMPLATE_BY_ID"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.CHECK_TRAINING_TEMPLATE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<TrainingTemplate>((resolve, reject) => {
      ApiService.get(`training-template/${id}/check`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE,
              data["training-template"]
            );
            resolve(data["training-template"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.commonGET_TRAINING_TEMPLATE_BY_ID"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.PAUSE_TRAINING_TEMPLATE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<TrainingTemplate>((resolve, reject) => {
      ApiService.get(`training-template/${id}/pause`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE,
              data["training-template"]
            );
            resolve(data["training-template"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.commonGET_TRAINING_TEMPLATE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FINISH_TEMPLATE_PERIOD_EXERCISE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<TemplatePeriodExercise>((resolve, reject) => {
      ApiService.get(`template-period-exercise/${id}/finish`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["template-period-exercise"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.commonGET_TEMPLATE_PERIOD_EXERCISE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_TRAINING_TEMPLATE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<TrainingTemplate>((resolve, reject) => {
      ApiService.delete(`training-template/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE,
              data["training-template"]
            );
            resolve(data["training-template"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.commonDELETE_TRAINING_TEMPLATE_BY_ID"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.UNLINK_TRAINING_TEMPLATE_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`training-template/${id}/unlink`)
        .then(({ data }) => {
          if (data.success) {
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.errors.commonUNLINK_TRAINING_TEMPLATE_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TRAINING_TEMPLATE](TrainingTemplate: TrainingTemplate) {
    const payload = JSON.parse(JSON.stringify(TrainingTemplate));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`training-template/${TrainingTemplate.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE,
              data["training-template"]
            );
            resolve();
          } else {
            if (data.errors.name[0].type == "unique") {
              const i18string = data.errors.name[0].i18;
              const i18params = {
                name: TrainingTemplate.name,
              };
              this.context.commit(Mutations.SET_ERROR, {
                i18string,
                i18params,
              });
            }
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.form.trainingTemplateChangedFail"
          );
          reject();
        });
    });
  }
  @Action
  [Actions.CREATE_SECONDARY_TRAINING_TEMPLATE](data: {
    primary_template_id: number;
    template: TrainingTemplate;
    length: number;
    start_at: string;
  }) {
    const payload = JSON.parse(JSON.stringify(data));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(
        `training-template/${data.primary_template_id}/link-secondary`,
        payload
      )
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE,
              data["training-template"]
            );
            resolve(data["secondary-training-template"]);
          } else {
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.form.secondaryTrainingTemplateLinkFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TRAINING_TEMPLATE](TrainingTemplate: TrainingTemplate) {
    const payload = JSON.parse(JSON.stringify(TrainingTemplate));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`training-template`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_LOADED_TRAINING_TEMPLATE,
              data["training-template"]
            );
            resolve(data["training-template"]);
          } else {
            if (data.errors.name[0].type == "unique") {
              const i18string = data.errors.name[0].i18;
              const i18params = {
                name: TrainingTemplate.name,
              };
              this.context.commit(Mutations.SET_ERROR, {
                i18string,
                i18params,
              });
            }
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "trainingTemplates.form.trainingTemplateAddFail"
          );
          reject();
        });
    });
  }
}
